import React, { useState, useEffect } from 'react';
import '../styles/WaitlistPage.css';
import { app } from '../firebase';
import { getFirestore, collection, addDoc } from 'firebase/firestore';

function WaitlistPage() {
  const [email, setEmail] = useState('');
  const [newsletter, setNewsletter] = useState(true);
  const [submitStatus, setSubmitStatus] = useState({ type: '', message: '' });
  const [showModal, setShowModal] = useState(false);
  const [showBadge, setShowBadge] = useState(true);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach(entry => {
          setShowBadge(!entry.isIntersecting);
        });
      },
      {
        threshold: 0.1
      }
    );

    const featuresSection = document.querySelector('.features-section');
    if (featuresSection) {
      observer.observe(featuresSection);
    }

    return () => {
      if (featuresSection) {
        observer.unobserve(featuresSection);
      }
    };
  }, []);

  const submitToWaitlist = async (withNewsletter) => {
    try {
      const db = getFirestore(app);
      const waitlistRef = collection(db, 'Waitlist');
      
      await addDoc(waitlistRef, {
        email: email,
        newsletter: withNewsletter,
        timestamp: new Date()
      });

      // Clear form and show success message
      setEmail('');
      setNewsletter(false);
      setSubmitStatus({
        type: 'success',
        message: 'Thank you for joining our waitlist!'
      });

    } catch (error) {
      console.error('Error adding to waitlist:', error);
      setSubmitStatus({
        type: 'error',
        message: 'Something went wrong. Please try again.'
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // Basic email validation
    if (!email || !/\S+@\S+\.\S+/.test(email)) {
      setSubmitStatus({
        type: 'error',
        message: 'Please enter a valid email address'
      });
      return;
    }

    if (!newsletter) {
      setShowModal(true);
    } else {
      await submitToWaitlist(true);
    }
  };

  const handleModalResponse = async (enableNewsletter) => {
    setShowModal(false);
    if (enableNewsletter) {
      setNewsletter(true);
      await submitToWaitlist(true);
    } else {
      await submitToWaitlist(false);
    }
  };

  const scrollToFeatures = () => {
    const featuresSection = document.querySelector('.features-section');
    featuresSection.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <div className="waitlist-container">
      {showModal && (
        <div className="modal-overlay">
          <div className="modal-content">
            <h3 className="modal-title">Stay Updated!</h3>
            <p className="modal-message">
              Would you like to receive updates about our launch and new features? 
              Don't miss out on important announcements and early access opportunities!
            </p>
            <div className="modal-actions">
              <button 
                className="modal-button primary"
                onClick={() => handleModalResponse(true)}
              >
                Enable Updates
              </button>
              <button 
                className="modal-button secondary"
                onClick={() => handleModalResponse(false)}
              >
                Skip Updates
              </button>
            </div>
          </div>
        </div>
      )}
      
      <div className="company-section">
        <h1 className="company-name">PoliTorium</h1>
        <div className="divider"></div>
        <h2 className="tagline">The Social Platform for <span className="highlight">Respectful Political Discourse.</span></h2>
        <p className="description">
          Join a community where diverse political views meet constructive dialogue. Experience politics differently with real conversations that bridge divides and foster understanding.
        </p>
        <form className="waitlist-form" onSubmit={handleSubmit}>
          <div className="input-group">
            <input 
              type="email" 
              placeholder="Enter your email address"
              className="email-input"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <button type="submit" className="join-button">Join the Waitlist</button>
          </div>
          <label className="newsletter-opt">
            <input 
              type="checkbox"
              checked={newsletter}
              onChange={(e) => setNewsletter(e.target.checked)}
            />
            Join the PoliTorium Newsletter!
          </label>
          {submitStatus.message && (
            <div className={`submit-status ${submitStatus.type}`}>
              {submitStatus.message}
            </div>
          )}
        </form>
        <div className="community-progress">
          <div className="member-count">
            <div className="member-avatars">
              <span className="avatar">
                <img src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?w=100&q=80" alt="Member" />
              </span>
              <span className="avatar">
                <img src="https://images.unsplash.com/photo-1507003211169-0a1dd7228f2d?w=100&q=80" alt="Member" />
              </span>
              <span className="avatar">
                <img src="https://images.unsplash.com/photo-1438761681033-6461ffad8d80?w=100&q=80" alt="Member" />
              </span>
              <span className="avatar">
                <img src="https://images.unsplash.com/photo-1500648767791-00dcc994a43e?w=100&q=80" alt="Member" />
              </span>
              <span className="avatar">
                <img src="https://images.unsplash.com/photo-1534528741775-53994a69daeb?w=100&q=80" alt="Member" />
              </span>
              <span className="avatar">
                <img src="https://images.unsplash.com/photo-1539571696357-5a69c17a67c6?w=100&q=80" alt="Member" />
              </span>
              <span className="avatar">
                <img src="https://images.unsplash.com/photo-1517841905240-472988babdf9?w=100&q=80" alt="Member" />
              </span>
              <span className="avatar">
                <img src="https://images.unsplash.com/photo-1524504388940-b1c1722653e1?w=100&q=80" alt="Member" />
              </span>
            </div>
            <p className="count-text">Join over 400 prelaunch users</p>
          </div>
        </div>
        {showBadge && (
          <div className="features-badge" onClick={scrollToFeatures}>
            <span className="features-badge-text">See Features</span>
            <span className="features-badge-arrow">
              <svg 
                width="20" 
                height="20" 
                viewBox="0 0 24 24" 
                fill="none" 
                xmlns="http://www.w3.org/2000/svg"
              >
                <path 
                  d="M12 5L12 19M12 19L19 12M12 19L5 12" 
                  stroke="currentColor" 
                  strokeWidth="2" 
                  strokeLinecap="round" 
                  strokeLinejoin="round"
                />
              </svg>
            </span>
          </div>
        )}
      </div>
      <div className="features-section">
        <h2 className="section-title">Features</h2>
        <div className="divider features-divider"></div>
        <div className="features-layout">
          <div className="content-column">
            <div className="post-card">
              <div className="feature-header">
                <div className="feature-title">Share Your Thoughts</div>
                <div className="feature-description">Engage with the community through thoughtful discussions and debates.</div>
              </div>
              <div className="post-header">
                <div className="user-info">
                  <span className="post-avatar">
                    <img src="https://images.unsplash.com/photo-1500648767791-00dcc994a43e?w=100&q=80" alt="Michael Kennedy" />
                  </span>
                  <div className="user-details">
                    <div className="name-party">
                      <span className="username">Michael Kennedy</span>
                      <span className="party-tag">Non Partisan</span>
                    </div>
                    <span className="post-time">2 hours ago</span>
                  </div>
                </div>
              </div>
              <div className="post-content">
                <p>The current healthcare system needs a comprehensive reform that focuses on accessibility and affordability. We need to find a middle ground that ensures quality care for all while maintaining innovation in medical research.</p>
              </div>
              <div className="post-actions">
                <div className="vote-pill">
                  <button className="vote-btn upvote">
                    <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M12 4L3 15H21L12 4Z" fill="currentColor"/>
                    </svg>
                    <span>2.4k</span>
                  </button>
                  <div className="vote-divider"></div>
                  <button className="vote-btn downvote">
                    <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M12 20L3 9H21L12 20Z" fill="currentColor"/>
                    </svg>
                    <span>128</span>
                  </button>
                </div>
                <button className="action-btn">
                  <svg width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M21 11.5C21 16.75 12 21 12 21C12 21 3 16.75 3 11.5C3 6.25 7.02944 2 12 2C16.9706 2 21 6.25 21 11.5Z" stroke="currentColor" strokeWidth="2"/>
                  </svg>
                  <span>46</span>
                </button>
                <button className="action-btn">
                  <svg width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M20 4L4 4L4 20M4 4L20 20" stroke="currentColor" strokeWidth="2" strokeLinecap="round"/>
                  </svg>
                  <span>89</span>
                </button>
                <button className="action-btn">
                  <svg width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M3 6H21M3 12H21M3 18H21" stroke="currentColor" strokeWidth="2" strokeLinecap="round"/>
                  </svg>
                  <span>1.2k</span>
                </button>
                <button className="action-btn">
                  <svg width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M19 21L12 16L5 21V3H19V21Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round"/>
                  </svg>
                </button>
              </div>
              <div className="post-tags">
                <span className="tag topic-tag">Healthcare</span>
                <span className="tag party-affiliation">Independent</span>
                <span className="tag moderation-status">Fact Checked ✓</span>
              </div>
            </div>
            <div className="poll-card">
              <div className="feature-header">
                <div className="feature-title">Create & Vote on Polls</div>
                <div className="feature-description">Participate in community polls and see real-time results.</div>
              </div>
              <div className="post-header">
                <div className="user-info">
                  <span className="post-avatar">
                    <img src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?w=100&q=80" alt="Rachel Smith" />
                  </span>
                  <div className="user-details">
                    <div className="name-party">
                      <span className="username">Rachel Smith</span>
                      <span className="party-tag">Non Partisan</span>
                    </div>
                    <span className="post-time">5 hours ago</span>
                  </div>
                </div>
              </div>
              
              <div className="post-content">
                <p>Which approach do you believe would be most effective in addressing climate change?</p>
              </div>

              <div className="poll-options">
                <div className="poll-option">
                  <div className="poll-option-header">
                    <span>Carbon Tax Implementation</span>
                    <span className="vote-percentage">42%</span>
                  </div>
                  <div className="poll-progress-bar">
                    <div className="poll-progress" style={{width: '42%'}}></div>
                  </div>
                  <span className="vote-count">3,486 votes</span>
                </div>

                <div className="poll-option">
                  <div className="poll-option-header">
                    <span>Renewable Energy Investment</span>
                    <span className="vote-percentage">35%</span>
                  </div>
                  <div className="poll-progress-bar">
                    <div className="poll-progress" style={{width: '35%'}}></div>
                  </div>
                  <span className="vote-count">2,905 votes</span>
                </div>

                <div className="poll-option">
                  <div className="poll-option-header">
                    <span>Nuclear Power Expansion</span>
                    <span className="vote-percentage">23%</span>
                  </div>
                  <div className="poll-progress-bar">
                    <div className="poll-progress" style={{width: '23%'}}></div>
                  </div>
                  <span className="vote-count">1,909 votes</span>
                </div>
              </div>

              <div className="poll-meta">
                <span className="total-votes">8,300 total votes</span>
                <span className="poll-time-left">2 days left</span>
              </div>

              <div className="post-actions">
                <div className="vote-pill">
                  <button className="vote-btn upvote">
                    <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M12 4L3 15H21L12 4Z" fill="currentColor"/>
                    </svg>
                    <span>1.8k</span>
                  </button>
                  <div className="vote-divider"></div>
                  <button className="vote-btn downvote">
                    <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M12 20L3 9H21L12 20Z" fill="currentColor"/>
                    </svg>
                    <span>245</span>
                  </button>
                </div>
                <button className="action-btn">
                  <svg width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M21 11.5C21 16.75 12 21 12 21C12 21 3 16.75 3 11.5C3 6.25 7.02944 2 12 2C16.9706 2 21 6.25 21 11.5Z" stroke="currentColor" strokeWidth="2"/>
                  </svg>
                  <span>124</span>
                </button>
                <button className="action-btn">
                  <svg width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M20 4L4 4L4 20M4 4L20 20" stroke="currentColor" strokeWidth="2" strokeLinecap="round"/>
                  </svg>
                  <span>56</span>
                </button>
              </div>

              <div className="post-tags">
                <span className="tag topic-tag">Climate Change</span>
                <span className="tag party-affiliation">Public Poll</span>
                <span className="tag moderation-status">Verified Poll ✓</span>
              </div>
            </div>
            <div className="user-insights-card">
              <div className="feature-header">
                <div className="feature-title">Track Your Impact</div>
                <div className="feature-description">Get detailed insights about your engagement and influence.</div>
              </div>
              <div className="post-header">
                <div className="user-info">
                  <span className="post-avatar">
                    <img src="https://images.unsplash.com/photo-1507003211169-0a1dd7228f2d?w=100&q=80" alt="John Doe" />
                  </span>
                  <div className="user-details">
                    <div className="name-party">
                      <span className="username">John Doe</span>
                      <span className="activity-status">Active Now</span>
                    </div>
                    <span className="member-since">Member since Sep 2023</span>
                  </div>
                </div>
              </div>

              <div className="insights-grid">
                <div className="insight-item">
                  <div className="insight-value">324</div>
                  <div className="insight-label">Total Posts</div>
                </div>
                <div className="insight-item">
                  <div className="insight-value">56</div>
                  <div className="insight-label">Active Polls</div>
                </div>
                <div className="insight-item">
                  <div className="insight-value">12</div>
                  <div className="insight-label">Petitions</div>
                </div>
                <div className="insight-item">
                  <div className="insight-value">89</div>
                  <div className="insight-label">Questions</div>
                </div>
              </div>

              <div className="engagement-metrics">
                <h4>Engagement Overview</h4>
                <div className="metrics-list">
                  <div className="metric-item">
                    <div className="metric-header">
                      <span>Total Upvotes</span>
                      <span className="metric-value positive">8.2k</span>
                    </div>
                    <div className="metric-bar">
                      <div className="metric-fill positive" style={{width: '80%'}}></div>
                    </div>
                  </div>
                  <div className="metric-item">
                    <div className="metric-header">
                      <span>Total Downvotes</span>
                      <span className="metric-value negative">1.3k</span>
                    </div>
                    <div className="metric-bar">
                      <div className="metric-fill negative" style={{width: '20%'}}></div>
                    </div>
                  </div>
                  <div className="metric-item">
                    <div className="metric-header">
                      <span>Response Rate</span>
                      <span className="metric-value">92%</span>
                    </div>
                    <div className="metric-bar">
                      <div className="metric-fill" style={{width: '92%'}}></div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="interaction-analysis">
                <h4>Political Interaction Analysis</h4>
                <div className="party-interactions">
                  <div className="party-item">
                    <div className="party-info">
                      <span>Independent</span>
                      <span>45%</span>
                    </div>
                    <div className="party-bar">
                      <div className="party-fill independent" style={{width: '45%'}}></div>
                    </div>
                  </div>
                  <div className="party-item">
                    <div className="party-info">
                      <span>Progressive</span>
                      <span>30%</span>
                    </div>
                    <div className="party-bar">
                      <div className="party-fill progressive" style={{width: '30%'}}></div>
                    </div>
                  </div>
                  <div className="party-item">
                    <div className="party-info">
                      <span>Conservative</span>
                      <span>25%</span>
                    </div>
                    <div className="party-bar">
                      <div className="party-fill conservative" style={{width: '25%'}}></div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="top-tags">
                <h4>Most Engaged Topics</h4>
                <div className="tag-cloud">
                  <span className="insight-tag high">Healthcare Reform</span>
                  <span className="insight-tag medium">Climate Policy</span>
                  <span className="insight-tag high">Education</span>
                  <span className="insight-tag low">Immigration</span>
                  <span className="insight-tag medium">Economy</span>
                  <span className="insight-tag low">Foreign Policy</span>
                </div>
              </div>

              <div className="activity-timeline">
                <h4>Recent Activity</h4>
                <div className="timeline">
                  <div className="timeline-item">
                    <div className="timeline-icon upvote"></div>
                    <div className="timeline-content">
                      <span className="activity-type">Upvoted a post about Healthcare Reform</span>
                      <span className="activity-time">2 hours ago</span>
                    </div>
                  </div>
                  <div className="timeline-item">
                    <div className="timeline-icon comment"></div>
                    <div className="timeline-content">
                      <span className="activity-type">Commented on Climate Policy Poll</span>
                      <span className="activity-time">5 hours ago</span>
                    </div>
                  </div>
                  <div className="timeline-item">
                    <div className="timeline-icon petition"></div>
                    <div className="timeline-content">
                      <span className="activity-type">Created a new petition</span>
                      <span className="activity-time">1 day ago</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          
          <div className="middle-column">
            <div className="ask-card">
              <div className="feature-header">
                <div className="feature-title">Connect with Officials</div>
                <div className="feature-description">Ask questions directly to verified public officials and get real answers.</div>
              </div>
              <div className="post-header">
                <div className="user-info">
                  <span className="post-avatar verified">
                    <img src="https://images.unsplash.com/photo-1438761681033-6461ffad8d80?w=100&q=80" alt="Senator Claire Wilson" />
                  </span>
                  <div className="user-details">
                    <div className="name-party">
                      <span className="username">Senator Claire Wilson</span>
                      <span className="verified-badge">
                        <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M9 12L11 14L15 10M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z" stroke="#fabfa8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                      </span>
                      <span className="official-tag">State Official</span>
                    </div>
                    <span className="post-time">Open for Questions • 3 days left</span>
                  </div>
                </div>
              </div>

              <div className="post-content">
                <p className="ask-title">Ask me about the New Environmental Policy Framework</p>
                <div className="ask-description">
                  I'll be answering questions about the recently proposed environmental policy framework, focusing on renewable energy transitions and community impact.
                </div>
              </div>

              <div className="ask-stats">
                <div className="stat-item">
                  <span className="stat-number">45</span>
                  <span className="stat-label">Questions Answered</span>
                </div>
                <div className="stat-item">
                  <span className="stat-number">128</span>
                  <span className="stat-label">Questions Pending</span>
                </div>
                <div className="stat-item">
                  <span className="stat-number">89%</span>
                  <span className="stat-label">Response Rate</span>
                </div>
              </div>

              <button className="ask-button">Ask a Question</button>

              <div className="top-questions">
                <h4>Top Questions</h4>
                <div className="question-list">
                  <div className="question-item">
                    <div className="question-header">
                      <span className="question-author">James Miller</span>
                      <span className="question-time">2 hours ago</span>
                    </div>
                    <p className="question-text">How will the new framework address job transitions in fossil fuel dependent communities?</p>
                    <div className="question-meta">
                      <span className="upvotes">
                        <svg width="12" height="12" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M12 4L3 15H21L12 4Z" fill="currentColor"/>
                        </svg>
                        234
                      </span>
                      <span className="status answered">Answered</span>
                    </div>
                  </div>

                  <div className="question-item">
                    <div className="question-header">
                      <span className="question-author">Sarah Chen</span>
                      <span className="question-time">5 hours ago</span>
                    </div>
                    <p className="question-text">What incentives will be provided for small businesses to adopt green technologies?</p>
                    <div className="question-meta">
                      <span className="upvotes">
                        <svg width="12" height="12" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M12 4L3 15H21L12 4Z" fill="currentColor"/>
                        </svg>
                        186
                      </span>
                      <span className="status pending">Pending</span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="post-actions">
                <div className="vote-pill">
                  <button className="vote-btn upvote">
                    <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M12 4L3 15H21L12 4Z" fill="currentColor"/>
                    </svg>
                    <span>1.2k</span>
                  </button>
                  <div className="vote-divider"></div>
                  <button className="vote-btn downvote">
                    <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M12 20L3 9H21L12 20Z" fill="currentColor"/>
                    </svg>
                    <span>45</span>
                  </button>
                </div>
                <button className="action-btn">
                  <svg width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M21 11.5C21 16.75 12 21 12 21C12 21 3 16.75 3 11.5C3 6.25 7.02944 2 12 2C16.9706 2 21 6.25 21 11.5Z" stroke="currentColor" strokeWidth="2"/>
                  </svg>
                  <span>324</span>
                </button>
                <button className="action-btn">
                  <svg width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M20 4L4 4L4 20M4 4L20 20" stroke="currentColor" strokeWidth="2" strokeLinecap="round"/>
                  </svg>
                  <span>156</span>
                </button>
              </div>

              <div className="post-tags">
                <span className="tag topic-tag">Environment</span>
                <span className="tag official-status">Official Q&A</span>
                <span className="tag moderation-status">Verified ✓</span>
              </div>
            </div>
            <div className="petition-card">
              <div className="feature-header">
                <div className="feature-title">Create & Sign Petitions</div>
                <div className="feature-description">Start or support petitions for meaningful change in your community.</div>
              </div>
              <div className="post-header">
                <div className="user-info">
                  <span className="post-avatar">
                    <img src="https://images.unsplash.com/photo-1539571696357-5a69c17a67c6?w=100&q=80" alt="David Harris" />
                  </span>
                  <div className="user-details">
                    <div className="name-party">
                      <span className="username">David Harris</span>
                      <span className="party-tag">Non Partisan</span>
                    </div>
                    <span className="post-time">1 day ago</span>
                  </div>
                </div>
              </div>
              
              <div className="post-content">
                <p>Petition to Implement Mandatory Ethics Training for All Elected Officials</p>
                <div className="petition-description">
                  We propose mandatory bi-annual ethics training for all elected officials to ensure transparency and accountability in governance.
                </div>
              </div>

              <div className="petition-progress">
                <div className="petition-stats">
                  <div className="signatures-count">12,458 signatures</div>
                  <div className="goal">of 15,000 goal</div>
                </div>
                <div className="petition-bar">
                  <div className="petition-fill" style={{width: '83%'}}></div>
                </div>
              </div>

              <button className="sign-petition-btn">Sign Petition</button>

              <div className="post-actions">
                <div className="vote-pill">
                  <button className="vote-btn upvote">
                    <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M12 4L3 15H21L12 4Z" fill="currentColor"/>
                    </svg>
                    <span>3.2k</span>
                  </button>
                  <div className="vote-divider"></div>
                  <button className="vote-btn downvote">
                    <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M12 20L3 9H21L12 20Z" fill="currentColor"/>
                    </svg>
                    <span>145</span>
                  </button>
                </div>
                <button className="action-btn">
                  <svg width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M21 11.5C21 16.75 12 21 12 21C12 21 3 16.75 3 11.5C3 6.25 7.02944 2 12 2C16.9706 2 21 6.25 21 11.5Z" stroke="currentColor" strokeWidth="2"/>
                  </svg>
                  <span>234</span>
                </button>
                <button className="action-btn">
                  <svg width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M20 4L4 4L4 20M4 4L20 20" stroke="currentColor" strokeWidth="2" strokeLinecap="round"/>
                  </svg>
                  <span>89</span>
                </button>
              </div>

              <div className="post-tags">
                <span className="tag topic-tag">Government Reform</span>
                <span className="tag party-affiliation">Public Petition</span>
                <span className="tag moderation-status">Verified ✓</span>
              </div>
            </div>
            <div className="news-card">
              <div className="feature-header">
                <div className="feature-title">Verified News</div>
                <div className="feature-description">Access fact-checked news from trusted sources with bias analysis.</div>
              </div>
              <div className="post-header">
                <div className="user-info">
                  <span className="post-avatar verified">
                    <img src="https://images.unsplash.com/photo-1529107386315-e1a2ed48a620?ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80" alt="Washington Post" />
                  </span>
                  <div className="user-details">
                    <div className="name-party">
                      <span className="username">Political Post</span>
                      <span className="verified-badge">
                        <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M9 12L11 14L15 10M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z" stroke="#fabfa8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                      </span>
                      <span className="official-tag">Verified News</span>
                    </div>
                    <span className="post-time">3 hours ago</span>
                  </div>
                </div>
              </div>

              <div className="news-content">
                <div className="news-image">
                  <img src="https://images.unsplash.com/photo-1529107386315-e1a2ed48a620?ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80" alt="Senate Building" />
                </div>
                <h3 className="news-title">Senate Passes Landmark Climate Bill with Bipartisan Support</h3>
                <p className="news-excerpt">
                  The legislation marks a significant shift in environmental policy, gaining support from both major parties after months of negotiation.
                </p>
              </div>

              <div className="news-meta">
                <div className="source-info">
                  <div className="reliability-score">
                    <span className="score-label">Reliability Score</span>
                    <div className="score-value high">98%</div>
                  </div>
                  <div className="fact-check">
                    <span className="fact-check-label">Fact Check Status</span>
                    <span className="fact-check-value verified">Verified</span>
                  </div>
                </div>
                <div className="bias-meter">
                  <span className="bias-label">Bias Analysis</span>
                  <div className="bias-scale">
                    <div className="bias-indicator" style={{left: '50%'}}></div>
                    <span className="bias-value">Neutral</span>
                  </div>
                </div>
              </div>

              <div className="news-stats">
                <div className="stat-item">
                  <span className="stat-number">15.2k</span>
                  <span className="stat-label">Reads</span>
                </div>
                <div className="stat-item">
                  <span className="stat-number">423</span>
                  <span className="stat-label">Citations</span>
                </div>
                <div className="stat-item">
                  <span className="stat-number">89%</span>
                  <span className="stat-label">Trust Score</span>
                </div>
              </div>

              <div className="post-actions">
                <div className="vote-pill">
                  <button className="vote-btn upvote">
                    <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M12 4L3 15H21L12 4Z" fill="currentColor"/>
                    </svg>
                    <span>2.8k</span>
                  </button>
                  <div className="vote-divider"></div>
                  <button className="vote-btn downvote">
                    <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M12 20L3 9H21L12 20Z" fill="currentColor"/>
                    </svg>
                    <span>95</span>
                  </button>
                </div>
                <button className="action-btn">
                  <svg width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M21 11.5C21 16.75 12 21 12 21C12 21 3 16.75 3 11.5C3 6.25 7.02944 2 12 2C16.9706 2 21 6.25 21 11.5Z" stroke="currentColor" strokeWidth="2"/>
                  </svg>
                  <span>512</span>
                </button>
                <button className="action-btn">
                  <svg width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M20 4L4 4L4 20M4 4L20 20" stroke="currentColor" strokeWidth="2" strokeLinecap="round"/>
                  </svg>
                  <span>246</span>
                </button>
              </div>

              <div className="post-tags">
                <span className="tag topic-tag">Politics</span>
                <span className="tag topic-tag">Climate</span>
                <span className="tag source-tag">Major Publication</span>
                <span className="tag moderation-status">Fact Checked ✓</span>
              </div>
            </div>
          </div>
          
          <div className="right-column">
            <div className="engagement-card">
              <div className="feature-header">
                <div className="feature-title">Post Analytics</div>
                <div className="feature-description">Track your content's performance with detailed engagement metrics and audience insights.</div>
              </div>
              <div className="engagement-header">
                <h3>Post Analytics</h3>
                <span className="time-range">Last 24 hours</span>
              </div>
              
              <div className="stats-grid">
                <div className="stat-item">
                  <div className="stat-value">24.5k</div>
                  <div className="stat-label">Total Views</div>
                  <div className="stat-trend positive">+12.4%</div>
                </div>
                <div className="stat-item">
                  <div className="stat-value">8.2k</div>
                  <div className="stat-label">Reactions</div>
                  <div className="stat-trend positive">+8.7%</div>
                </div>
              </div>

              <div className="engagement-section">
                <h4>Geographic Distribution (US)</h4>
                <div className="region-stats">
                  <div className="region-item">
                    <div className="region-info">
                      <span>California</span>
                      <span>42%</span>
                    </div>
                    <div className="progress-bar">
                      <div className="progress" style={{width: '42%'}}></div>
                    </div>
                  </div>
                  <div className="region-item">
                    <div className="region-info">
                      <span>New York</span>
                      <span>35%</span>
                    </div>
                    <div className="progress-bar">
                      <div className="progress" style={{width: '35%'}}></div>
                    </div>
                  </div>
                  <div className="region-item">
                    <div className="region-info">
                      <span>Texas</span>
                      <span>23%</span>
                    </div>
                    <div className="progress-bar">
                      <div className="progress" style={{width: '23%'}}></div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="engagement-section">
                <h4>Political Affiliation</h4>
                <div className="affiliation-stats">
                  <div className="affiliation-item">
                    <div className="affiliation-info">
                      <span>Independent</span>
                      <span>45%</span>
                    </div>
                    <div className="progress-bar">
                      <div className="progress independent" style={{width: '45%'}}></div>
                    </div>
                  </div>
                  <div className="affiliation-item">
                    <div className="affiliation-info">
                      <span>Progressive</span>
                      <span>30%</span>
                    </div>
                    <div className="progress-bar">
                      <div className="progress progressive" style={{width: '30%'}}></div>
                    </div>
                  </div>
                  <div className="affiliation-item">
                    <div className="affiliation-info">
                      <span>Conservative</span>
                      <span>25%</span>
                    </div>
                    <div className="progress-bar">
                      <div className="progress conservative" style={{width: '25%'}}></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="location-card">
              <div className="feature-header">
                <div className="feature-title">Local Political Analytics</div>
                <div className="feature-description">Stay informed about your local political landscape, representatives, and community issues.</div>
              </div>
              <div className="location-header">
                <div className="location-info">
                  <h3>Westminster, Colorado</h3>
                  <span className="location-type">City</span>
                </div>
                <button className="change-location">
                  <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7z" stroke="currentColor" strokeWidth="2"/>
                    <circle cx="12" cy="9" r="2.5" stroke="currentColor" strokeWidth="2"/>
                  </svg>
                  Change
                </button>
              </div>

              <div className="location-stats">
                <div className="stat-item">
                  <div className="stat-value">12.4k</div>
                  <div className="stat-label">Active Users</div>
                </div>
                <div className="stat-item">
                  <div className="stat-value">892</div>
                  <div className="stat-label">Daily Posts</div>
                </div>
              </div>

              <div className="location-section">
                <h4>Local Political Distribution</h4>
                <div className="distribution-stats">
                  <div className="distribution-item">
                    <div className="distribution-info">
                      <span>Independent</span>
                      <span>38%</span>
                    </div>
                    <div className="distribution-bar">
                      <div className="distribution-fill independent" style={{width: '38%'}}></div>
                    </div>
                  </div>
                  <div className="distribution-item">
                    <div className="distribution-info">
                      <span>Democrat</span>
                      <span>35%</span>
                    </div>
                    <div className="distribution-bar">
                      <div className="distribution-fill democrat" style={{width: '35%'}}></div>
                    </div>
                  </div>
                  <div className="distribution-item">
                    <div className="distribution-info">
                      <span>Republican</span>
                      <span>27%</span>
                    </div>
                    <div className="distribution-bar">
                      <div className="distribution-fill republican" style={{width: '27%'}}></div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="location-section">
                <h4>Top Local Issues</h4>
                <div className="issues-list">
                  <div className="issue-item">
                    <span className="issue-name">Infrastructure Development</span>
                    <span className="issue-count">1.2k discussions</span>
                  </div>
                  <div className="issue-item">
                    <span className="issue-name">Education Funding</span>
                    <span className="issue-count">856 discussions</span>
                  </div>
                  <div className="issue-item">
                    <span className="issue-name">Water Conservation</span>
                    <span className="issue-count">634 discussions</span>
                  </div>
                </div>
              </div>

              <div className="location-section">
                <h4>Local Representatives</h4>
                <div className="representatives-list">
                  <div className="representative-item">
                    <div className="rep-info">
                      <span className="post-avatar">
                        <img src="https://images.unsplash.com/photo-1500648767791-00dcc994a43e?w=100&q=80" alt="Faith Borland" />
                      </span>
                      <div className="rep-details">
                        <span className="rep-name">Faith Borland</span>
                        <span className="rep-role">City Council Member</span>
                      </div>
                    </div>
                    <button className="follow-btn">Follow</button>
                  </div>
                  <div className="representative-item">
                    <div className="rep-info">
                      <span className="post-avatar">
                        <img src="https://images.unsplash.com/photo-1507003211169-0a1dd7228f2d?w=100&q=80" alt="Jon Martinez" />
                      </span>
                      <div className="rep-details">
                        <span className="rep-name">Jon Martinez</span>
                        <span className="rep-role">Mayor</span>
                      </div>
                    </div>
                    <button className="follow-btn">Follow</button>
                  </div>
                </div>
              </div>

              <div className="location-section">
                <h4>Upcoming Local Events</h4>
                <div className="events-list">
                  <div className="event-item">
                    <div className="event-date">
                      <span className="date">24</span>
                      <span className="month">JUN</span>
                    </div>
                    <div className="event-details">
                      <span className="event-name">Town Hall Meeting</span>
                      <span className="event-location">City Hall • 7:00 PM</span>
                    </div>
                  </div>
                  <div className="event-item">
                    <div className="event-date">
                      <span className="date">28</span>
                      <span className="month">JUN</span>
                    </div>
                    <div className="event-details">
                      <span className="event-name">Public Budget Discussion</span>
                      <span className="event-location">Community Center • 6:30 PM</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="privacy-section">
        <h2 className="section-title">Your Privacy Matters</h2>
        <div className="divider features-divider"></div>
        <div className="privacy-cards">
          <div className="privacy-card">
            <div className="privacy-icon">🔒</div>
            <h3 className="privacy-card-title">Data Protection</h3>
            <p className="privacy-card-text">
              Your personal information is encrypted and securely stored. We never share your data with third parties without your explicit consent.
            </p>
          </div>
          <div className="privacy-card">
            <div className="privacy-icon">🛡️</div>
            <h3 className="privacy-card-title">Content Security</h3>
            <p className="privacy-card-text">
            Advanced moderation tools and AI systems ensure a safe and friendly environment free from harassment, trolling, and spam.
            </p>
          </div>
          <div className="privacy-card">
            <div className="privacy-icon">✅</div>
            <h3 className="privacy-card-title">Verified Information</h3>
            <p className="privacy-card-text">
              Our fact-checking system and trusted source verification ensure you receive accurate, unbiased political information.
            </p>
          </div>
        </div>
      </div>
      <footer className="footer">
        <div className="footer-content">
          <div className="footer-left">
            <a href="mailto:support@politorium.com" className="footer-email">
              <svg 
                width="24" 
                height="24" 
                viewBox="0 0 24 24" 
                fill="none" 
                xmlns="http://www.w3.org/2000/svg"
              >
                <path 
                  d="M4 4H20C21.1 4 22 4.9 22 6V18C22 19.1 21.1 20 20 20H4C2.9 20 2 19.1 2 18V6C2 4.9 2.9 4 4 4Z" 
                  stroke="currentColor" 
                  strokeWidth="2" 
                  strokeLinecap="round" 
                  strokeLinejoin="round"
                />
                <path 
                  d="M22 6L12 13L2 6" 
                  stroke="currentColor" 
                  strokeWidth="2" 
                  strokeLinecap="round" 
                  strokeLinejoin="round"
                />
              </svg>
              <span>support@politorium.com</span>
            </a>
            <div className="footer-links">
              <a 
                href="https://drive.google.com/file/d/1W6nmu0hgPX1i1wD-QFNNvyJOXEHx_loW/view?usp=sharing" 
                className="footer-link"
                target="_blank"
                rel="noopener noreferrer"
              >
                Privacy Policy
              </a>
              <span className="footer-divider">|</span>
              <a 
                href="https://drive.google.com/file/d/1S0DwOU_EJPmo5TkOzMxPoX95A7nKA6l3/view?usp=sharing" 
                className="footer-link"
                target="_blank"
                rel="noopener noreferrer"
              >
                Terms of Service
              </a>
            </div>
          </div>
          <div className="footer-right">
            <div className="footer-brand">
              <h3 className="footer-title">PoliTorium</h3>
              <p className="footer-tagline">The Social Platform for Respectful Political Discourse.</p>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default WaitlistPage; 