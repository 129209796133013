import './App.css';
import WaitlistPage from './components/WaitlistPage';

function App() {
  return (
    <div className="App">
      <WaitlistPage />
    </div>
  );
}

export default App;
